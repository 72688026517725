<template>
  <v-container>
    <v-row class="text-center" align="center" style="height: 600px;">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Developmental Research Management System
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>
