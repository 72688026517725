var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","disable-pagination":"","fixed-header":"","single-select":"","no-data-text":"The family hasn't participated in any study or no family is selected.","headers":this.$headersAppointmentsBrief,"items":_vm.Appointments,"height":"450px","calculate-widths":""},scopedSlots:_vm._u([{key:"item.Schedule.AppointmentTime",fn:function(ref){
var item = ref.item;
return [_c('DateDisplay',{attrs:{"date":item.Schedule.AppointmentTime,"format":'long',"status":item.Schedule.Status}})]}},{key:"item.Schedule.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('DateDisplay',{attrs:{"date":item.Schedule.updatedAt,"format":'short',"status":item.Schedule.Status}})]}},{key:"item.AgeByParticipation",fn:function(ref){
var item = ref.item;
return [_c('AgeByParticipation',{attrs:{"item":item}})]}},{key:"item.Schedule.Status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.Schedule.Status, item.Schedule.Completed),"dark":""}},[_vm._v(" "+_vm._s(item.Schedule.Status == "Confirmed" && item.Schedule.Completed ? "Completed" : item.Schedule.Status)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }