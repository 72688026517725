var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.$store.state.labEmailStatus)?_c('div',[_c('v-alert',{staticStyle:{"font-weight":"600"},attrs:{"border":"left","type":"error","color":"#c73460","dense":""}},[_vm._v("Lab email is not been setup properly. Please set it up in the Settings page.")])],1):_vm._e(),(!_vm.$store.state.adminEmailStatus)?_c('div',[_c('v-alert',{staticStyle:{"font-weight":"600"},attrs:{"border":"left","type":"warning","color":"#c7792c","dense":""}},[_vm._v("Admin email is not been setup properly. Please set it up in the Settings page.")])],1):_vm._e(),(_vm.$store.state.trainingMode)?_c('div',[_c('v-alert',{staticStyle:{"font-weight":"600"},attrs:{"border":"left","type":"warning","color":"#c7792c","dense":""}},[_vm._v("You are running in a training mode.")])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search by Name or Email","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-checkbox',{attrs:{"label":"Active only","hide-details":""},model:{value:(_vm.activeMemberFilter),callback:function ($$v) {_vm.activeMemberFilter=$$v},expression:"activeMemberFilter"}})],1)]}}])},[_c('span',[_vm._v("Show active members")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":"600","single-select":"","no-data-text":"No personnel to display.","headers":_vm.headerPersonnel,"items":_vm.Personnels,"search":_vm.search,"custom-filter":_vm.filterByText},on:{"click:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"item.Active",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-simple-checkbox',{staticClass:"mr-0 pa-0",attrs:{"value":!!item.Active,"disabled":!(
                        _vm.currentPersonnel.id == _vm.$store.state.userID ||
                        _vm.$store.state.role == 'Admin' ||
                        _vm.$store.state.role == 'PI' ||
                        _vm.$store.state.role == 'Lab manager'
                      ),"dense":""},on:{"input":function($event){return _vm.changePersonnelStatus(item)}}})],1)]}}],null,true)},[_c('span',[_vm._v("Mark whether this person is available to run studies")])])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-col',{staticClass:"subtitle",attrs:{"md":"12"}},[_c('v-divider'),_c('h4',{staticClass:"text-left"},[_vm._v("Personnel information:")])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',_vm._l((_vm.personnelFields),function(item){return _c('v-col',{key:item.label,attrs:{"cols":"12","sm":"6","md":item.width}},[_c('v-text-field',{attrs:{"background-color":"textbackground","label":item.label,"value":item.label === 'Phone'
                    ? _vm.PhoneFormated(_vm.currentPersonnel[item.field])
                    : _vm.currentPersonnel[item.field],"hide-details":"","height":"48px","placeholder":"  ","outlined":"","dense":""}})],1)}),1),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"2","dense":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":_vm.$store.state.role != 'Admin' &&
                            _vm.$store.state.role != 'PI' &&
                            _vm.$store.state.role != 'PostDoc' &&
                            _vm.$store.state.role != 'GradStudent' &&
                            _vm.$store.state.role != 'Lab manager'},on:{"click":function($event){$event.stopPropagation();return _vm.createPersonnel.apply(null, arguments)}}},[_c('v-icon',_vm._b({staticClass:"fabIcon",attrs:{"left":""}},'v-icon',_vm.iconSize,false),[_vm._v("add")]),_vm._v("Add a person ")],1)],1)]}}])},[_c('span',[_vm._v("Add a new person to the lab")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2","dense":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":!_vm.currentPersonnel.id ||
                            (_vm.currentPersonnel.id != _vm.$store.state.userID &&
                              _vm.$store.state.role != 'Admin' &&
                              _vm.$store.state.role != 'PI' &&
                              _vm.$store.state.role != 'Lab manager')},on:{"click":function($event){$event.stopPropagation();return _vm.editPersonnel.apply(null, arguments)}}},[_c('v-icon',_vm._b({staticClass:"fabIcon",attrs:{"left":""}},'v-icon',_vm.iconSize,false),[_vm._v("edit")]),_vm._v("Update info ")],1)],1)]}}])},[_c('span',[_vm._v("Edit personnel information")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2","dense":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":!_vm.currentPersonnel.id ||
                            (_vm.$store.state.role != 'Admin' &&
                              _vm.$store.state.role != 'PI' &&
                              _vm.$store.state.role != 'Lab manager')},on:{"click":function($event){$event.stopPropagation();return _vm.deletePersonnel.apply(null, arguments)}}},[_c('v-icon',_vm._b({staticClass:"fabIcon",attrs:{"left":""}},'v-icon',_vm.iconSize,false),[_vm._v("delete")]),_vm._v("delete ")],1)],1)]}}])},[_c('span',[_vm._v("Remove this person from the lab")])])],1)],1)],1),_c('v-col',{staticClass:"subtitle",attrs:{"md":"12"}},[_c('v-divider'),_c('h4',{staticClass:"text-left"},[_vm._v("Assigned studies:")])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('AssignedStudies',{attrs:{"Studies":_vm.currentPersonnel.AssignedStudies,"labStudies":_vm.labStudies,"personnelId":_vm.currentPersonnel.id,"personnelName":_vm.currentPersonnel.Name},on:{"updatedStudies":_vm.updatedStudies}})],1)],1)],1),_c('div',[_c('v-dialog',{attrs:{"max-width":"1000px","retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Lab member information")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{staticStyle:{"padding":"8px 8px 4px"},attrs:{"dense":""}},_vm._l((_vm.personnelFields),function(item){return _c('v-col',{key:item.label,attrs:{"cols":"12","sm":"6","md":item.width}},[(item.options)?_c('div',[_c('v-select',{attrs:{"justify":"start","items":_vm.$store.state.role == 'Admin' ||
                              _vm.$store.state.role == 'PI' ||
                              _vm.$store.state.role == 'Lab manager'
                                ? _vm.$Options.fullRoles
                                : _vm.$Options.limitedRoles,"label":item.label,"rules":_vm.$rules[item.rules],"hide-details":"","height":"48px","placeholder":"  ","outlined":"","dense":"","chip":""},model:{value:(_vm.editedPersonnel[item.field]),callback:function ($$v) {_vm.$set(_vm.editedPersonnel, item.field, $$v)},expression:"editedPersonnel[item.field]"}})],1):(item.rules)?_c('div',[_c('v-text-field',{attrs:{"label":item.label,"rules":_vm.$rules[item.rules],"hide-details":"","height":"48px","placeholder":"  ","outlined":"","dense":""},model:{value:(_vm.editedPersonnel[item.field]),callback:function ($$v) {_vm.$set(_vm.editedPersonnel, item.field, $$v)},expression:"editedPersonnel[item.field]"}})],1):_c('div',[_c('v-text-field',{attrs:{"label":item.label,"hide-details":"","height":"48px","placeholder":"  ","outlined":"","dense":""},model:{value:(_vm.editedPersonnel[item.field]),callback:function ($$v) {_vm.$set(_vm.editedPersonnel, item.field, $$v)},expression:"editedPersonnel[item.field]"}})],1)])}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}})],1)],1)],1)],1),_c('v-card-actions',{staticStyle:{"padding":"16px"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"4"}}),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1),_c('v-col',{attrs:{"md":"4"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }